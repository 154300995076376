import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/otp-verification',
    name: 'OtpVerification',
    // route level code-splitting
    // this generates a separate chunk (otp-verification.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "otp-verification" */ '../views/OtpVerification.vue')
  },
  {
    path: '/member-registration',
    name: 'MembershipRegistration',
    // route level code-splitting
    // this generates a separate chunk (member-registration.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "member-registration" */ '../views/MembershipRegistration.vue')
  },
  {
    path: '/enroll-with-membership-id',
    name: 'EnrollWithMembershipId',
    // route level code-splitting
    // this generates a separate chunk (entol-with-id.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "enroll-with-id" */ '../views/EnrollWithId.vue')
  },
  {
    path: '/enrolment-for-the-year-has-ended',
    name: 'Expired',
    // route level code-splitting
    // this generates a separate chunk (entol-with-id.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "enroll-with-id" */ '../components/Expired.vue')
  },
  {
    path: '/update-payment-details',
    name: 'UpdatePaymentDetails',
    // route level code-splitting
    // this generates a separate chunk (payment-details.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "payment-details" */ '../views/UpdatePaymentDetails.vue'),
    //props: true
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if(to.name !== 'Expired') {
//     next('/enrolment-for-the-year-has-ended')
//   } else {
//     next()
//   }
  
// })



export default router
