import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue, IconsPlugin, ToastPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import moment from 'moment'

/**
 * styles
 */
import './assets/sass/style.scss'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'notyf/notyf.min.css'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueCompositionAPI)
Vue.use(ToastPlugin)

axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "crossdomain": true,
  "Access-Control-Allow-Origin": "*"
};

Vue.prototype.$axios = axios.create({
  baseURL: 'https://welfare-backend.securus.in/api/kpcs/'
  //baseURL: 'https://staging-welfare-backend.securus.in/api/kpcs/'
})

Vue.prototype.$axiosEnrollment = axios.create({
  //baseURL: 'https://welfare-backend.securus.in/api/employee-enrollment/'
  //baseURL: 'https://staging-welfare-backend.securus.in/api/kpcs/'
  baseURL: 'https://welfare-backend.securus.in/api/employee-enrollment/'
  //baseURL: 'https://welfare-backend.securus.in/api/employee-enrollment/'
})

Vue.prototype.$axiosFile = axios.create({
  baseURL: 'https://welfare-backend.securus.in/api/'
})


Vue.prototype.$moment = moment()


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
